/* eslint-disable react-hooks/exhaustive-deps */
import MainContainer from "../../layout/MainContainer";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useLazyGetTrainingQuery } from "../../services/trainer";
import { Training } from "../../types/General";
import { useEffect, useState } from "react";
import { showError } from "../../constants";

const TraningLocation = () => {
  const [getTrainingMutation] = useLazyGetTrainingQuery();
  const [training, setTraining] = useState<Training[]>([]);
  // const [selectedMarker, setSelectedMarker] = useState<Training | null>(null);

  const mapContainerStyle = {
    height: "500px",
    width: "100%",
  };

  const getTraining = async () => {
    try {
      const res = await getTrainingMutation({
        page: 1,
        limit: 10,
        query: "",
      }).unwrap();
      if (res?.statusCode === 200) {
        setTraining(res?.data?.traning || []);
      }
    } catch (error: any) {
      console.log(error, "eroro");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getTraining();
  }, []);

  // const handleMarkerClick = (marker: any) => {
  //   setSelectedMarker(marker);
  // };
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1> Training Location</h1>
        </div>
        <GoogleMap
          zoom={13}
          mapContainerStyle={mapContainerStyle}
          center={{
            lat: training[0]?.latitude || 21.178574,
            lng: training[0]?.longitude || 72.814149,
          }}
        >
          {training?.length
            ? training?.map((marker: Training, i) => (
                <Marker
                  key={i}
                  position={{
                    lat: marker?.latitude,
                    lng: marker?.longitude,
                  }}
                  // onClick={() => handleMarkerClick(marker)}
                >
                  {/* {selectedMarker === marker && (
                    <InfoWindow
                      position={{
                        lat: marker?.latitude,
                        lng: marker?.longitude,
                      }}
                      onCloseClick={() => handleMarkerClick(null)}
                    >
                      <div style={{ padding: "10px", background: "white" }}>
                        <h3>{marker?.address1}</h3>
                      </div>
                    </InfoWindow>
                  )} */}
                </Marker>
              ))
            : null}
        </GoogleMap>
        {/* <GoogleMap
          zoom={13}
          mapContainerStyle={mapContainerStyle}
          center={{
            lat: training[0]?.latitude || 21.178574,
            lng: training[0]?.longitude || 72.814149,
          }}
        >
          {training?.length
            ? training?.map((marker: Training, i) => (
                <Marker
                  key={i}
                  position={{
                    lat: marker?.latitude,
                    lng: marker?.longitude,
                  }}
                  onClick={() => handleMarkerClick(marker)}
                >
                  {selectedMarker === marker && (
                    <InfoWindow
                      position={{
                        lat: marker?.latitude,
                        lng: marker?.longitude,
                      }}
                      onCloseClick={() => handleMarkerClick(null)}
                    >
                      <div className="price_marker_detail">
                        <div className="map_detail_box">
                          <h2>{marker?.address1 || ""}</h2>
                        </div>
                      </div>
                     
                    </InfoWindow>
                  )}
                </Marker>
              ))
            : null}
        </GoogleMap> */}

        {/* <GoogleMap
          zoom={13}
          mapContainerStyle={mapContainerStyle}
          center={{
            lat: training[0]?.latitude || 21.178574,
            lng: training[0]?.longitude || 72.814149,
          }}
          // onClick={() => handleMarkerClick(item)}
        >
          {training?.length
            ? training?.map((marker: Training, i) => (
                <Marker
                  key={i}
                  position={{
                    lat: marker?.latitude,
                    lng: marker?.longitude,
                  }}
                  onClick={() => handleMarkerClick(marker)}
                >
                  <InfoWindow
                    position={{
                      lat: marker?.latitude,
                      lng: marker?.longitude,
                    }}
                    onCloseClick={() => handleMarkerClick(null)}
                  >
                    {selectedMarker === marker ? (
                      <div style={{ position: "absolute", background: "red" }}>
                        yes
                      </div>
                    ) : (
                      <div>No</div>
                    )}
                  </InfoWindow>
                </Marker>
              ))
            : null}
        </GoogleMap> */}
      </div>
    </MainContainer>
  );
};

export default TraningLocation;
