import React from "react";
import "./App.scss";
import Routing from "./Routes";

function App() {

  console.log = () => {};

  return <Routing />;
}

export default App;
