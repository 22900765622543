import LoginPage from "./auth/login";
import ForgotPasswordPage from "./auth/forgotPassword";
import VerifyOtp from "./auth/otp";
import ResetPassword from "./auth/resetPassword";
import Profile from "./profile";
import CreateMaterial from "./createTrainingMaterial";
import AddTrainingMaterial from "./createTrainingMaterial/add";
import AddTrainingLocation from "./createTraining/add";
import ManageTraingLocation from "./createTraining";
import TraningLocation from "./trainingLocation";
import ChangePassword from "./auth/changePassword";
import ViewTraining from "./createTraining/view";
import ViewTrainingMaterial from "./createTrainingMaterial/view";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginPage,
  ForgotPasswordPage,
  VerifyOtp,
  ResetPassword,
  Profile,
  AddTrainingMaterial,
  CreateMaterial,
  ManageTraingLocation,
  AddTrainingLocation,
  TraningLocation,
  ChangePassword,
  ViewTraining,
  ViewTrainingMaterial,
};
