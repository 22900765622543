import { Routes, Route, useNavigate } from "react-router-dom";
import Pages from "./pages";
import { useEffect } from "react";
import { STORAGE_KEYS, getFromStorage } from "./constants";
import { useAppDispatch } from "./hooks/store";
import { resetAuth, setCredentials } from "./reducers/authSlice";

const Routing = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    if (token && window.location.pathname === "/") {
      dispatch(
        setCredentials({
          token: token,
          user: null,
        })
      );
      navigate("/manage-training", { replace: true });
    } else if (!token && window?.location?.pathname === "/") {
      dispatch(resetAuth());
      navigate("/", { replace: true });
    } else if (token) {
      dispatch(
        setCredentials({
          token: token,
          user: null,
        })
      );
      navigate(window?.location?.pathname);
    } else {
      dispatch(resetAuth());
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="forgotpassword" element={<Pages.ForgotPasswordPage />} />
      <Route path="verifyotp" element={<Pages.VerifyOtp />} />
      <Route path="resetpassword" element={<Pages.ResetPassword />} />
      <Route path="/profile" element={<Pages.Profile />} />
      <Route path="/changepassword" element={<Pages.ChangePassword />} />
      <Route path="/training-material" element={<Pages.CreateMaterial />} />
      <Route
        path="/trainingmaterial/add"
        element={<Pages.AddTrainingMaterial />}
      />
      <Route
        path="/trainingmaterial/edit/:id"
        element={<Pages.AddTrainingMaterial />}
      />
      <Route
        path="/trainingmaterial/view/:id"
        element={<Pages.ViewTrainingMaterial />}
      />
      <Route path="/manage-training" element={<Pages.ManageTraingLocation />} />
      <Route path="/training/add" element={<Pages.AddTrainingLocation />} />
      <Route
        path="/training/edit/:id"
        element={<Pages.AddTrainingLocation />}
      />
      <Route path="/training/view/:id" element={<Pages.ViewTraining />} />
      <Route path="/training-location" element={<Pages.TraningLocation />} />
    </Routes>
  );
};

export default Routing;
