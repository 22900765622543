import { END_POINTS } from "../constants/url";
import { Category, Training, TraningMaterial } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type category = {
  category: Category[];
};

type materialBody = {
  name: string;
  googleDriveLink: string;
  categoryId: string;
  contentType: string;
  docLink: string;
  description: string;
};

type trainingBody = {
  title: string;
  description: string;
  date: string;
  latitude: string | number;
  longitude: string | number;
};

type GetMaterialList = {
  totalTraningMaterial: number;
  traningMaterial: TraningMaterial[];
};

type GetTrainingList = {
  totalTraning: number;
  traning: Training[];
};

type GetTokenParams = {
  limit: number;
  page: number;
  query: string;
};
export const trainerApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategory: builder.query<CommonResponseType & { data: category }, {}>({
      query: () => ({
        url: `${END_POINTS.getCategory}`,
        method: "GET",
      }),
    }),
    getTrainingMaterial: builder.query<
      CommonResponseType & { data: GetMaterialList },
      GetTokenParams
    >({
      query: ({ limit, page, query }) => ({
        url:
          END_POINTS.getTraningMaterial +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getMaterialById: builder.query<
      CommonResponseType & { data: TraningMaterial },
      { material_id: string }
    >({
      query: ({ material_id }) => ({
        url: `${END_POINTS.getTraningMaterial}/${material_id}`,
        method: "GET",
      }),
    }),
    addTrainingMaterial: builder.mutation<
      CommonResponseType & { data: GetMaterialList },
      materialBody
    >({
      query: (body) => ({
        url: END_POINTS.addTraningMaterial,
        method: "POST",
        body,
      }),
    }),
    editTrainingMaterial: builder.mutation<
      CommonResponseType & { data: GetMaterialList },
      { material_id: string; body: materialBody }
    >({
      query: ({ body, material_id }) => ({
        url: `${END_POINTS.editTraningMaterial}/${material_id}`,
        method: "POST",
        body,
      }),
    }),
    updateStatusMaterial: builder.mutation<
      CommonResponseType & { data: GetMaterialList },
      {
        material_id: string;
        body: { isActive: boolean };
      }
    >({
      query: ({ body, material_id }) => ({
        url: `${END_POINTS.editTraningMaterial}/${material_id}`,
        method: "POST",
        body,
      }),
    }),
    deleteMaterialById: builder.query<
      CommonResponseType,
      { material_id: string }
    >({
      query: ({ material_id }) => ({
        url: `${END_POINTS.deleteTraningMaterial}/${material_id}`,
        method: "DELETE",
      }),
    }),

    //addtraining
    getTraining: builder.query<
      CommonResponseType & { data: GetTrainingList },
      GetTokenParams
    >({
      query: ({ limit, page, query }) => ({
        // url: `${END_POINTS.getTraning}`,
        url:
          END_POINTS.getTraning +
          "?page=" +
          page +
          "&limit=" +
          limit +
          "&search=" +
          query,
        method: "GET",
      }),
    }),

    getTrainingById: builder.query<
      CommonResponseType & { data: Training },
      { training_id: string }
    >({
      query: ({ training_id }) => ({
        url: `${END_POINTS.getTraning}/${training_id}`,
        method: "GET",
      }),
    }),

    addTraining: builder.mutation<
      CommonResponseType & { data: GetTrainingList },
      trainingBody
    >({
      query: (body) => ({
        url: END_POINTS.addTraning,
        method: "POST",
        body,
      }),
    }),
    deleteTrainingById: builder.query<
      CommonResponseType & { data: GetTrainingList },
      { training_id: string }
    >({
      query: ({ training_id }) => ({
        url: `${END_POINTS.deleteTraning}/${training_id}`,
        method: "DELETE",
      }),
    }),
    editTraining: builder.mutation<
      CommonResponseType & { data: GetTrainingList },
      { training_id: string; body: trainingBody }
    >({
      query: ({ body, training_id }) => ({
        url: `${END_POINTS.editTraning}/${training_id}`,
        method: "POST",
        body,
      }),
    }),
    updateStatusTraining: builder.mutation<
      CommonResponseType & { data: GetTrainingList },
      {
        training_id: string;
        body: { isActive: boolean };
      }
    >({
      query: ({ body, training_id }) => ({
        url: `${END_POINTS.editTraning}/${training_id}`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetCategoryQuery,
  useLazyGetTrainingMaterialQuery,
  useAddTrainingMaterialMutation,
  useLazyGetMaterialByIdQuery,
  useEditTrainingMaterialMutation,
  useAddTrainingMutation,
  useLazyGetTrainingQuery,
  useLazyGetTrainingByIdQuery,
  useLazyDeleteMaterialByIdQuery,
  useLazyDeleteTrainingByIdQuery,
  useEditTrainingMutation,
  useUpdateStatusMaterialMutation,
  useUpdateStatusTrainingMutation,
} = trainerApi;
