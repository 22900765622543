/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useAddTrainingMaterialMutation,
  useEditTrainingMaterialMutation,
  useLazyGetCategoryQuery,
  useLazyGetMaterialByIdQuery,
} from "../../services/trainer";
import { Category, TraningMaterial } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import { isNumber, isString } from "../../utils/validations";

const AddTrainingMaterial = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [getCategoriesMutation] = useLazyGetCategoryQuery();
  const [addMaterial, { isLoading }] = useAddTrainingMaterialMutation();
  const [editMaterial, editMaterialData] = useEditTrainingMaterialMutation();
  const [getMaterialById, getMaterialByIdData] = useLazyGetMaterialByIdQuery();

  const [contentType, setContentType] = useState("");
  const [category, setCategory] = useState("");
  const [error, setError] = useState(false);
  const [categoryRes, setCategoryRes] = useState<Category[]>([]);
  const [material, setMaterial] = useState<TraningMaterial | null>(null);

  const handleChange = (event: SelectChangeEvent) => {
    setContentType(event.target.value as string);
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: material?.name || "",
      description: material?.description || "",
      videoLink: material?.googleDriveLink || "",
      docLink: material?.docLink || "",
      nameAr: material?.title_ar || "",
      descriptionAr: material?.description_ar || "",
      sessionCount: material?.noOfSession || "",
      hoursCount: material?.noOfHours || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("This field is required")
        .max(30, "Maximum 30 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      nameAr: Yup.string()
        // .required("This field is required")
        .max(30, "Maximum 30 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      description: Yup.string()
        .required("This field is required")
        .min(3, "Minimum 2 characters are required"),
      descriptionAr: Yup.string()
        // .required("This field is required")
        .min(2, "Minimum 2 characters are required"),
      videoLink: Yup.string()
        // .required("This field is required")
        .min(2, "Minimum 2 characters are required"),
      docLink: Yup.string()
        // .required("This field is required")
        .min(2, "Minimum 2 characters are required"),
      sessionCount: Yup.string(),
      // .required("This field is required")
      hoursCount: Yup.string(),
      // .required("This field is required")
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      if (!category || !contentType) {
        return;
      }
      const body = {
        name: formik.values.name,
        googleDriveLink: formik.values.videoLink,
        docLink: formik.values.docLink,
        categoryId: category,
        contentType: contentType,
        description: formik.values.description,
        title_ar: formik.values.nameAr,
        description_ar: formik.values.descriptionAr,
        noOfHours: formik.values.hoursCount,
        noOfSession: formik.values.sessionCount,
      };
      if (id) {
        try {
          const response = await editMaterial({
            body,
            material_id: id,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message || "");
            navigate("/training-material");
          }
        } catch (error: any) {
          console.log(error);
          showError(error?.data?.message || "");
        }
      } else {
        try {
          const response = await addMaterial(body).unwrap();
          if (response?.statusCode === 200) {
            showToast("Material Added Successfully");
            navigate("/training-material");
          }
        } catch (error: any) {
          console.log(error);
          showError(error?.data?.message || "");
        }
      }
    },
  });

  const getCategories = async () => {
    try {
      const res = await getCategoriesMutation({}).unwrap();
      if (res?.statusCode === 200) {
        setCategoryRes(res?.data?.category);
      }
    } catch (error) {
      console.log(error, "eroro");
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleGetMaterialById = async (id: string) => {
    try {
      const response = await getMaterialById({ material_id: id }).unwrap();
      if (response?.statusCode === 200) {
        setMaterial(response?.data);
        setContentType(response?.data?.contentType);
        setCategory(response?.data?.categoryId?._id);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      handleGetMaterialById(id);
    }
  }, []);

  return (
    <>
      <Loader
        isLoad={
          getMaterialByIdData?.isLoading ||
          isLoading ||
          editMaterialData?.isLoading
        }
      />
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{id ? "Edit Traning Material" : "Add Traning Material"}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/training-material");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className="custom_label">Title</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="name"
                      variant="outlined"
                      fullWidth
                      placeholder="Enter Title"
                      id="name"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Title(Arabic)
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="nameAr"
                      variant="outlined"
                      fullWidth
                      placeholder="Enter Title(Arabic)"
                      id="nameAr"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.nameAr}
                      helperText={formik.touched.nameAr && formik.errors.nameAr}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Description
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder="Description"
                      fullWidth
                      multiline
                      minRows={1.2}
                      maxRows={4}
                      name="description"
                      variant="outlined"
                      id="description"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Description(Arabic)
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder="Description(Arabic)"
                      fullWidth
                      multiline
                      minRows={1.2}
                      maxRows={4}
                      name="descriptionAr"
                      variant="outlined"
                      id="descriptionAr"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.descriptionAr}
                      helperText={
                        formik.touched.descriptionAr &&
                        formik.errors.descriptionAr
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Number of Sessions
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="sessionCount"
                      variant="outlined"
                      fullWidth
                      placeholder="Number of Sessions"
                      id="sessionCount"
                      onBlur={formik.handleBlur}
                      value={formik.values.sessionCount}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.sessionCount &&
                        formik.errors.sessionCount
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Number of Hours
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="hoursCount"
                      variant="outlined"
                      fullWidth
                      placeholder="  Number of Hours"
                      id="hoursCount"
                      onBlur={formik.handleBlur}
                      value={formik.values.hoursCount}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.hoursCount && formik.errors.hoursCount
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Video Link{/* Google Drive Link */}
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="videoLink"
                      variant="outlined"
                      fullWidth
                      placeholder="Add Link"
                      id="videoLink"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.videoLink}
                      helperText={
                        formik.touched.videoLink && formik.errors.videoLink
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography className="custom_label">Doc Link</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      name="docLink"
                      variant="outlined"
                      fullWidth
                      placeholder="Add Link"
                      id="docLink"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.docLink}
                      helperText={
                        formik.touched.docLink && formik.errors.docLink
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Content Type
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        className="select_div"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={contentType}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleChange}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        <MenuItem value="Images">Images</MenuItem>
                        <MenuItem value="Videos">Videos</MenuItem>
                        <MenuItem value="Presentation">Presentation</MenuItem>
                      </Select>
                      {error && !contentType ? (
                        <h6 className="err_msg">This field is required</h6>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">Categories</Typography>
                    <FormControl fullWidth>
                      <Select
                        className="select_div"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleCategoryChange}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {categoryRes?.length
                          ? categoryRes?.map((item) => (
                              <MenuItem value={item?._id}>
                                {item?.name || ""}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      {error && !category ? (
                        <h6 className="err_msg">This field is required</h6>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => setError(true)}
                  >
                    {id ? "Update" : "Save"}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddTrainingMaterial;
