import { PropsWithoutRef } from "react";
import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import { SIDEBAR_WIDTH } from "../constants";

interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const drawer = (
    <>
      <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
        <figure>
          <img
            src="/static/images/logo.png"
            alt=""
            // onClick={() => navigate("/dashboard")}
          />
        </figure>
        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "white",
            display: { lg: "none" },
          }}
          onClick={handleSidebarToggle}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>

      <List sx={{ flexGrow: 1 }} className="sidebr-lst">
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/manage-training" ||
            location.pathname === "/training/add" ||
            location.pathname === `/training/edit/${id}` ||
            location.pathname === `/training/view/${id}`
          }
          onClick={() => navigate("/manage-training")}
        >
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Events" />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={
            location.pathname === "/training-material" ||
            location.pathname === "/trainingmaterial/add" ||
            location.pathname === `/trainingmaterial/edit/${id}` ||
            location.pathname === `/trainingmaterial/view/${id}`
          }
          onClick={() => navigate("/training-material")}
        >
          <ListItemIcon>
            <NoteAddIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary="Manage Training Material"
          />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/training-location"}
          onClick={() => navigate("/training-location")}
        >
          <ListItemIcon>
            <LocationOnIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Training Location" />
        </ListItemButton>
      </List>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
