export const API_URL = "https://kwapi.appgrowthcompany.com/api/v1/trainer/"; //development
// export const API_URL = "https://api.kwsaudiarabia.com/api/v1/trainer/";

export const WORDPRESS_URL =
  "https://kwadmin.appgrowthcompany.com/wp-json/custom/v1/page/english/marketcenter";

export const END_POINTS = {
  login: "login",
  forgotPassword: "forgotPassword",
  get_user: "profile",
  update_profile: "updateprofile",
  mediaUpload: "uploadFile",
  verifyOtp: "verifyOtp",
  resetPassword: "setPassword",
  changePassword: "changePassword",

  //get category
  getCategory: "getCategory",

  //training material
  getTraningMaterial: "getTraningMaterial",
  addTraningMaterial: "addTraningMaterial",
  editTraningMaterial: "editTraningMaterial",
  deleteTraningMaterial: "deleteTraningMaterial",

  //training
  addTraning: "addTraning",
  getTraning: "getTraning",
  editTraning: "editTraning",
  deleteTraning: "deleteTraning",
};
