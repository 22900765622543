import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import MainContainer from "../../layout/MainContainer";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import { useFormik } from "formik";
import { UploadMedia } from "../../utils/mediaUpload";
import {
  Loader,
  STORAGE_KEYS,
  getFromStorage,
  showError,
  showToast,
} from "../../constants";
import { Autocomplete } from "@react-google-maps/api";
import { useEditProfileMutation } from "../../services/auth";
import { setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/store";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import { isString } from "../../utils/validations";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

type headerType = {
  scrollPosition: ScrollPosition;
};

const Profile = ({ scrollPosition }: headerType) => {
  const userData = useAuth();
  const dispatch = useAppDispatch();
  const [image, setImage] = useState<string>("");
  const [phoneCode, setPhoneCode] = useState("+966");
  const [error, setError] = useState(false);
  const [frontImg, setFrontImg] = useState<string>("");
  const [backImg, setBackImg] = useState<string>("");
  const [autocomplete, setAutocomplete] = useState(null);

  const [updateProfile, { isLoading }] = useEditProfileMutation();

  const handleImageUpload = async (
    event: ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    try {
      const res = await UploadMedia(file);
      if (res?.statusCode === 200) {
        if (key === "profile") {
          setImage(res?.data);
        } else if (key === "front") {
          setFrontImg(res?.data);
        } else {
          setBackImg(res?.data);
        }
      } else {
        showError(res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // for country picker
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: userData?.firstName || "",
      lastName: userData?.lastName || "",
      email: userData?.email || "",
      phone: userData?.phone || "",
      address1: userData?.address1 || "",
      city: userData?.city || "",
      zipcode: userData?.zipcode || "",
      docName: userData?.documet?.name || "",
      expiredOn: userData?.documet?.expireAt
        ? moment(userData?.documet?.expireAt).format("YYYY-MM-DD")
        : "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      lastName: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
      address1: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      city: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      zipcode: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      docName: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      expiredOn: Yup.string().required("This field is required"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let body = {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.email,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        phone: formik.values.phone,
        address1: formik.values.address1,
        city: formik.values.city,
        zipcode: formik.values.zipcode,
        image: image,
        expired: moment(formik.values.expiredOn)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        documet: {
          name: formik.values.docName,
          expireAt: moment(formik.values.expiredOn)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          frontImage: frontImg,
          backImage: backImg,
        },
      };

      try {
        const response = await updateProfile(body).unwrap();
        console.log(response);
        if (response?.statusCode === 200) {
          const token = getFromStorage(STORAGE_KEYS.token);
          showToast("Profile updated successfully");
          dispatch(
            setCredentials({
              user: response?.data || null,
              token: token || null,
            })
          );
        } else {
          showError(response?.message || "");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  const onLoad = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);
  };

  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any).getPlace();

      let address = place.address_components;
      let city = "",
        zip = "";
      address.forEach(function (component: any) {
        let types = component.types;
        if (types.indexOf("locality") > -1) {
          city = component.long_name;
        }
        if (types.indexOf("postal_code") > -1) {
          zip = component.long_name;
        }
        var lat = place.geometry.location.lat();
        // get lng
        var lng = place.geometry.location.lng();

        formik.setFieldValue("address1", `${place?.formatted_address}`);
        formik.setFieldValue("city", city || "");
        formik.setFieldValue("zipcode", zip || "");
        formik.setFieldValue("lat", lat || "");
        formik.setFieldValue("long", lng || "");
        // if (types.indexOf("sublocality_level_1") > -1) {
        //   addLine2 = component?.long_name;
        // }

        // if (types.indexOf("administrative_area_level_1") > -1) {
        //   state = component.long_name;
        // }
      });

      // get lat

      // var latLong = {
      //   lat: lat,
      //   lng: lng,
      // };
    }
  };

  const onKeypress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      return false;
    }
  };

  useEffect(() => {
    setPhoneCode(userData?.countryCode || "+966");
    setImage(userData?.image || "");
    setFrontImg(userData?.documet?.frontImage || "");
    setBackImg(userData?.documet?.backImage || "");
  }, [userData]);

  return (
    <>
      <Loader isLoad={isLoading} />
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Edit Profile</h1>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <LazyLoadImage
                          alt="photo"
                          src={
                            image
                              ? image
                              : "/static/images/user_placeholder.png"
                          }
                          effect="blur"
                          scrollPosition={scrollPosition}
                        />
                        {/* <Avatar
                          sx={{
                            width: "100px",
                            height: "100px",
                            bgcolor: grey[400],
                            fontSize: 34,
                          }}
                          alt={userData?.firstName}
                          src={image}
                        /> */}
                        <CancelIcon
                          style={{ zIndex: 100 }}
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleImageUpload(e, "profile")}
                            // onChange={(
                            //   event: ChangeEvent<HTMLInputElement>
                            // ) => {
                            //   const files = (event.target as HTMLInputElement)
                            //     .files;
                            //   if (files && files[0].type.includes("image")) {
                            //     setFileName(files[0].name);
                            //     setImage(URL.createObjectURL(files[0]));
                            //   } else {
                            //     setAlertType(0);
                            //     setShowAlert(true);
                            //     setAlertMessage(
                            //       "This field only accepts images."
                            //     );
                            //   }
                            // }}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/user_placeholder.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  {/* <Grid item xs={3}>
                    <Typography className="custom_label">
                      Account Status : Pending
                    </Typography>
                  </Grid> */}
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Trainer First Name
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      variant="outlined"
                      fullWidth
                      placeholder="First Name"
                      name="firstName"
                      id="firstName"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Trainer Last Name
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      variant="outlined"
                      fullWidth
                      placeholder="Last Name"
                      name="lastName"
                      id="lastName"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      disabled
                      className="text_field"
                      hiddenLabel
                      type={"email"}
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                      name="email"
                      id="email"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      helperText={formik.touched.email && formik.errors.email}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      enableSearch={true}
                      value={phoneCode + formik.values.phone}
                      country={"us"}
                      inputClass="phoneInput"
                      inputStyle={{ width: "100%" }}
                      buttonClass="phoneBtn"
                      placeholder="Phone Number"
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <Typography className="custom_label">Address</Typography>
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={() => onPlaceChanged()}
                    >
                      <TextField
                        className="text_field"
                        hiddenLabel
                        type={"text"}
                        variant="outlined"
                        fullWidth
                        placeholder="Address line 1"
                        name="address1"
                        id="address1"
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.address1}
                        helperText={
                          formik.touched.address1 && formik.errors.address1
                        }
                        onKeyPress={onKeypress}
                      />
                    </Autocomplete>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography className="custom_label">City</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      variant="outlined"
                      fullWidth
                      placeholder="City"
                      name="city"
                      id="city"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.city}
                      helperText={formik.touched.city && formik.errors.city}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">Zipcode</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      variant="outlined"
                      fullWidth
                      placeholder="Zipcode"
                      name="zipcode"
                      id="zipcode"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.zipcode}
                      helperText={
                        formik.touched.zipcode && formik.errors.zipcode
                      }
                    ></TextField>
                  </Grid>

                  <Grid item xs={12} className="heading">
                    <Typography className="custom_label" variant="h5">
                      Document Details
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">Name</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      variant="outlined"
                      fullWidth
                      placeholder="Document Name"
                      name="docName"
                      id="docName"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.docName}
                      helperText={
                        formik.touched.docName && formik.errors.docName
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Expiring On
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"date"}
                      variant="outlined"
                      fullWidth
                      placeholder="Document Name"
                      name="expiredOn"
                      id="expiredOn"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.expiredOn}
                      helperText={
                        formik.touched.expiredOn && formik.errors.expiredOn
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Front Image
                    </Typography>

                    <div className="upload_document w_50">
                      {frontImg ? (
                        <div className="inner">
                          <div className="cross_icn">
                            <CloseIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                setFrontImg("");
                              }}
                            />
                          </div>

                          <figure>
                            <LazyLoadImage
                              width={"100%"}
                              height={"100%"}
                              alt="photo"
                              src={frontImg ? frontImg : ""}
                              effect="blur"
                              scrollPosition={scrollPosition}
                            />
                          </figure>
                        </div>
                      ) : (
                        <label htmlFor="icon-button-file-front">
                          <div className="inner">
                            <span>
                              <img src="static/images/icon_upload.svg" alt="" />
                            </span>
                            <Input
                              id="icon-button-file-front"
                              type="file"
                              inputProps={{
                                accept: "image/png,image/jpeg",
                              }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => handleImageUpload(e, "front")}
                            />
                          </div>

                          {error && !frontImg ? (
                            <h6 className="err_msg">This field is required</h6>
                          ) : null}
                        </label>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Back Image</Typography>
                    <div className="upload_document w_50">
                      {backImg ? (
                        <div className="inner">
                          <div className="cross_icn">
                            <CloseIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                setBackImg("");
                              }}
                            />
                          </div>

                          <figure>
                            <LazyLoadImage
                              width={"100%"}
                              height={"100%"}
                              alt="photo"
                              src={backImg ? backImg : ""}
                              effect="blur"
                              scrollPosition={scrollPosition}
                            />
                          </figure>
                        </div>
                      ) : (
                        <label htmlFor="icon-button-file-back">
                          <div className="inner">
                            <span>
                              <img src="static/images/icon_upload.svg" alt="" />
                            </span>
                            <Input
                              id="icon-button-file-back"
                              type="file"
                              inputProps={{
                                accept: "image/png,image/jpeg",
                              }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => handleImageUpload(e, "back")}
                            />
                          </div>

                          {error && !backImg ? (
                            <h6 className="err_msg">This field is required</h6>
                          ) : null}
                        </label>
                      )}
                    </div>
                  </Grid>
                </Grid>

                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => setError(true)}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default trackWindowScroll(Profile);
